<template>
  <v-dialog persistent v-model="isOpen" max-width="800px">
    <v-card class="paper-preview-card">
      <v-card-title style="color: white;" class="secondary">
        Preview
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog" class="white--text">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div
          class="text-justify pa-3 paper-preview-content"
          style="max-height: 600px; overflow-y: auto;"
        >
          <div ref="body">
            <!-- Display law info -->
            <v-row align="center" justify="space-between" class="mb-2">
              <v-col cols="auto">
                <h3 class="font-weight-medium" v-if="jsonData?.aka" v-html="jsonData?.aka"></h3>
                <h3 class="font-weight-medium" v-if="getLawCode(jsonData?.lawTag, jsonData?.lawNo)" v-html="getLawCode(jsonData?.lawTag, jsonData?.lawNo)"></h3>
              </v-col>
              <v-col cols="auto">
                <p class="text-overline">{{ formattedDate(jsonData?.approvalDate) }}</p>
              </v-col>
            </v-row>
            <h3 class="text-center text-h4 font-weight-bold" v-html="jsonData?.officialTitle"></h3>
            <p class="text-h6 ma-4 text-center font-weight-medium" v-if="jsonData?.longTitle" v-html="jsonData?.longTitle"></p>
            <hr class="my-2" v-if="jsonData?.longTitle" />

            <!-- Render the main contents array -->
            <div v-for="(content, contentIdx) in jsonData.contents" :key="'content' + contentIdx">
              <recursive-tier :tier="content" />
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>  
  </v-dialog>
</template>

<script>
import RecursiveTier from './LawPreviewRecursive.vue';

export default {
  components: {
    RecursiveTier,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    jsonData: {
      type: [Array, Object],
      required: true,
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    formattedDate(dateString) {
      if (!dateString) return '';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    getLawCode(lawTag, lawNo) {
      // Return early for tags that do not require a law number
      if (lawTag === "Constitutional Document" || lawTag === "Spanish Decree") {
        return "";
      }
      const lawTagMap = {
        "Republic Act": "RA",
        "Executive Order": "EO",
        "Presidential Decree": "PD",
        "Batas Pambansa": "BP",
        "Letters of Instruction": "LOI",
        "Commonwealth Acts": "CA",
        "Acts of Legislature": "Act"
      };
      const prefix = lawTagMap[lawTag] || "";
      return lawNo ? ` | <strong>${prefix} ${lawNo}</strong>` : "";
    },
  },
};
</script>

<style scoped>
/* Style for JSON Preview */
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* Style for the blockquote */
.blockquote-custom {
  padding: 0.5em;
  color: #555;
}

.paper-preview-content {
  font-size: 0.875rem !important;
}

.paper-preview-card {
  margin: auto;
  width: 100%;
}
</style>
