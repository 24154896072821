<template>
  <v-responsive>
    <v-card :class="themeClass" flat style="display: flex; flex-direction: column; max-height: 100vh;">
      <!-- Scrollable Content Area -->
      <div
        class="text-justify paper-preview-content"
        style="flex: 1; overflow-y: auto; max-height: calc(100vh - 10.5rem);"
      >
        <div ref="body">
          <v-stepper v-model="step" vertical>
            <!-- Step 1: Law Form -->
            <v-stepper-step :complete="step > 1" step="1">
              {{ isNew ? 'Create Law' : 'Edit Law' }} | <strong>{{ law?.officialTitle }}</strong>
            </v-stepper-step>
            <v-stepper-content step="1">
              <LawForm
                :law="law"
                :datePickerMenu="datePickerMenu"
                ref="lawForm"
              />
            </v-stepper-content>

            <!-- Step 2: Law Content -->
            <v-stepper-step :complete="step > 2" step="2">
              {{ 'Edit Law Content' }}
            </v-stepper-step>
            <v-stepper-content step="2">
              <LawEditor
                v-if="!loading"
                :law="law"
                @update-law-contents="updateLawContents"
              />
            </v-stepper-content>
          </v-stepper>
          <!-- Section Preview Dialog -->
          <LawPreviewDialog
            :isOpen="isJsonPreviewDialogOpen"
            :jsonData="rawArray"
            @close="isJsonPreviewDialogOpen = false"
          />

          <v-dialog v-model="saving" persistent width="300">
            <v-card outlined>
              <v-card-text class="text-center">
                <div class="mt-3">Saving law, please wait...</div>
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>

          <!-- Alert -->
          <v-alert
            v-if="alert"
            dense
            text
            dismissible
            transition="scale-transition"
            :type="alertType"
            @input="alert = false"
          >
            {{ response }}
          </v-alert>
        </div>
      </div>

      <!-- Button Row (Always Visible) -->
      <div>
        <v-row align="center" justify="space-between">
          <!-- Left buttons: Cancel, Preview, Save -->
          <v-col cols="auto">
            <v-btn class="ma-2" color="white" small rounded light @click="cancelEdit">
              Cancel
            </v-btn>
            <v-btn
              v-if="step === 2"
              key="save-btn"
              class="ma-2"
              color="primary"
              small
              rounded
              :disabled="!isLawChanged"
              @click="saveLaw"
            >
              Save
            </v-btn>
            <v-btn v-if="showPreviewButton" class="ma-2" small rounded color="secondary" @click="printArray">
              <v-icon left>mdi-eye</v-icon>
              Preview Law
            </v-btn>
          </v-col>

          <!-- Spacer between left and right buttons -->
          <v-spacer></v-spacer>

          <!-- Right buttons: Next, Back -->
          <v-col cols="auto">
            <v-btn
              v-if="step === 1"
              key="next-btn"
              color="primary"
              class="ma-2"
              small
              rounded
              @click="nextStep"
            >
              Next
            </v-btn>

            <v-btn
              v-if="step === 2"
              key="back-btn"
              class="ma-2"
              color="primary"
              small
              rounded
              @click="prevStep"
            >
              Back
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-responsive>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { lawsCollection, sectionsCollection } from '@/firebaseDb';
import LawForm from './LawForm.vue';
import LawEditor from './editor/LawEditor.vue'; 
import LawPreviewDialog from './LawPreview.vue';

export default {
  components: {
    LawForm,
    LawEditor, 
    LawPreviewDialog,
  },
  data() {
    return {
      step: 1,
      lawId: this.$route.params.id,
      law: {
        lawTag: '',
        lawNo: '',
        lawCategory: '',
        officialTitle: '',
        longTitle: '',
        aka: '',
        approvalDate: '',
        publicationDate: '',
        contents: [],
        editor: '',
      },
      originalLaw: null,
      isLawChanged: false,
      isNew: this.$route.params.id === 'new',
      datePickerMenu: {
        approvalDate: false,
        publicationDate: false,
      },
      isJsonPreviewDialogOpen: false,
      rawArray: {},
      alert: false,
      alertType: '',
      response: '',
      loading: true,
      saving: false,
    };
  },
  computed: {
    ...mapState(['currentUser', 'settings']),
    ...mapGetters(['darkTheme']),
    themeClass() {
      return this.darkTheme ? 'dark-theme' : 'light-theme';
    },
    showPreviewButton() {
      return !(this.step < 2 && !this.isNew);
    },
  },
  created() {
    if (!this.isNew) {
      this.loadLaw();
    } else {
      this.loading = false;
    }
    this.originalLaw = JSON.parse(JSON.stringify(this.law));
  },
  watch: {
    law: {
      handler(newVal) {
        this.isLawChanged = this.detectChanges(newVal, this.originalLaw);
      },
      deep: true
    }
  },
  methods: {
    printArray() {
      this.rawArray = JSON.parse(JSON.stringify(this.law));
      this.isJsonPreviewDialogOpen = true;
    },
    nextStep() {
      this.$router.push({ name: 'law-editor', params: { id: this.lawId } });
      this.step = 2;
    },
    prevStep() {
      this.step = 1;
      this.$router.push({ name: 'laws-edit', params: { id: this.lawId } });
    },
    cancelEdit() {
      this.$router.push({ name: 'laws-list' });
    },
    detectChanges(current, original) {
      const excludedKeys = ['contents'];
      for (const key in original) {
        if (excludedKeys.includes(key)) {
          continue;
        }
        if (Object.prototype.hasOwnProperty.call(original, key)) {
          if (JSON.stringify(current[key]) !== JSON.stringify(original[key])) {
            return true;
          }
        }
      }
      return false;
    },
    async saveLaw() {
      if (!this.isLawChanged) {
        this.showAlert('warning', 'No changes detected.');
        return;
      }
      this.saving = true;
      try {
        if (!this.law.lawTag) {
          this.showAlert('error', 'Law type is required.');
          this.saving = false;
          return;
        }
        if (this.law.lawTag !== "Constitutional Document" &&
          this.law.lawTag !== "Spanish Decree" &&
          !this.law.lawNo) {
          this.showAlert('error', 'Law number is required for the selected type.');
          this.saving = false;
          return;
        }

        if (this.law.contents.length > 0) {
          await this.saveContents();
        }

        this.law.editor = `users/${this.currentUser.uid}`;
        if (this.isNew) {
          const docRef = await lawsCollection.add(this.law);
          this.lawId = docRef.id;
          this.$router.push({ name: 'laws-edit', params: { id: this.lawId } });
        } else {
          await lawsCollection.doc(this.lawId).update(this.law);
        }
        this.$router.push({ name: 'laws-list' });
      } catch (error) {
        this.showAlert('error', 'Error saving the law.');
      } finally {
        this.saving = false; 
      }
    },
    loadLaw() {
      lawsCollection.doc(this.lawId).get().then((doc) => {
        if (doc.exists) {
          this.law = doc.data();
          this.loading = false;
        } else {
          console.error('No such law!');
        }
      }).catch((error) => {
        this.loading = false;
        console.error('Error getting document:', error);
      });
    },
    updateLawContents(updatedContents) {
      this.law.contents = updatedContents;
    },
    async saveContents() {
      try {
        const contentsReferences = [];
        for (const tier of this.law.contents) {
          if (tier.listItems && tier.listItems.length > 0) {
            tier.listItems = await this.saveListItems(tier.listItems);
          }
          if (tier.subTiers && tier.subTiers.length > 0) {
            await this.saveSubTiers(tier.subTiers);
          }
          contentsReferences.push(tier);
        }
        this.law.contents = contentsReferences;
      } catch (error) {
        this.showAlert('error', 'Error saving contents.');
      }
    },

    async saveListItems(listItems) {
      const listItemReferences = [];
      for (let listGroup of listItems) {
        let listGroupDocRef;

        if (listGroup.id && listGroup.id !== 'new') {
          listGroupDocRef = sectionsCollection.doc(listGroup.id);
          await listGroupDocRef.set({
            listTitle: listGroup.listTitle,
            listItems: listGroup.items,
          }, { merge: true });
        } else {
          listGroupDocRef = sectionsCollection.doc();
          listGroup.id = listGroupDocRef.id;
          await listGroupDocRef.set({
            listTitle: listGroup.listTitle,
            listItems: listGroup.items,
          });
        }
        listItemReferences.push({ id: listGroupDocRef.id });
      }
      return listItemReferences;
    },

    async saveSubTiers(subTiers) {
      for (const subTier of subTiers) {
        if (subTier.listItems && subTier.listItems.length > 0) {
          subTier.listItems = await this.saveListItems(subTier.listItems);
        }
        if (subTier.subTiers && subTier.subTiers.length > 0) {
          await this.saveSubTiers(subTier.subTiers);
        }
      }
    }
  },
};
</script>