<template>
  <v-card 
    :class="themeClass"
    :id="generateId(path)"
    :style="{ backgroundColor: getColorByLevel(tier.level) }"
    light
    class="mb-2"
  >
    <v-card-title v-if="tier.level">
      <v-row align="center" no-gutters>
        <v-col cols="2" class="mr-1">
          <span class="text-caption">No.</span>
          <v-text-field
            v-model="tier.number"
            label="No."
            solo
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col>
          <span class="text-caption">Title</span>
          <v-text-field
            v-model="tier.name"
            label="Title"
            solo
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="auto" class="mt-6">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" :tabindex="-1">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
  
            <v-list dense>
              <v-list-item @click="$emit('removeTier', path)">
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$emit('addSubTier', path)">
                <v-list-item-title>Add Subheading</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$emit('addContent', path)">
                <v-list-item-title>Edit Content</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$emit('addListGroup', path, { listTitle: '' })">
                <v-list-item-title>Add List</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tooltip bottom dense>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="handle" v-bind="attrs" v-on="on" :tabindex="-1">
                <v-icon>mdi-drag</v-icon>
              </v-btn>
            </template>
            <span>Drag item</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>

    <!-- Content Section with Remove Button -->
    <v-card-subtitle v-if="tier.content !== undefined">
      <v-row align="center">
        <v-col>
          <span class="text-caption">Content</span>
          <ckeditor
            :editor="editor"
            v-model="tier.content"
            :config="editorConfig"
          ></ckeditor>
        </v-col>
        <v-col cols="auto">
          <v-btn x-small fab color="primary" @click="$emit('removeContent', path)" :tabindex="-1">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-subtitle>

    <v-card-subtitle v-if="tier.listItems">
      <v-row align="center">
          <LawSections
            v-for="(group, groupIndex) in tier.listItems"
            :key="groupIndex"
            :listItems="group.items || []" 
            :groupTitle="group.listTitle"
            :path="path"
            :groupIndex="groupIndex" 
            :loading="loading"   
            @addListItem="addListItemHandler"
            @removeListItem="removeListItem"
            @updateListItem="updateListItemHandler"
            @listTitle="updateListTitle"
            @removeListGroup="removeListGroup"
            @addListGroup="addListGroup"
          />
      </v-row>
    </v-card-subtitle>

    <!-- Draggable Sub-tiers with Recursive Nesting -->
    <draggable 
      v-model="tier.subTiers" 
      :group="{ name: `level-${tier.level + 1}`, pull: true, put: [`level-${tier.level + 1}`] }"
      animation="200" 
      handle=".handle"
      class="ma-3"
    >
      <tier-item
        v-for="(subTier, subTierIndex) in tier.subTiers"
        :key="subTierIndex"
        :tier="subTier"
        :path="[...path, subTierIndex]"
        :groupIndex="subTierIndex"  
        :generateId="generateId"
        :loading="loading" 
        @removeTier="$emit('removeTier', $event)"
        @addSubTier="$emit('addSubTier', $event)"
        @addContent="$emit('addContent', $event)"
        @removeContent="$emit('removeContent', $event)"
        @addListItem="addListItemHandler"
        @removeListItem="removeListItem"
        @updateListItem="updateListItemHandler"
        @listTitle="updateListTitle"
        @removeListGroup="removeListGroup"
        @addListGroup="addListGroup"
      />
    </draggable>
    <v-card-actions class="d-flex justify-end">
      <v-btn 
        v-if="tier.level > 1" 
        x-small 
        color="secondary"
        rounded
        @click="$emit('addSubTier', getNewPath(path))"
      >
        add
      </v-btn>
        <v-btn 
          v-if="!tier.level" 
          x-small 
          color="secondary"
          rounded
          @click="$emit('addListGroup', path, { listTitle: '' })"
        >
          add new list
        </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import { mapGetters } from "vuex";
import LawSections from "./LawSections.vue"

export default {
  name: "TierItem",
  props: {
    tier: Object,
    path: Array,
    generateId: Function,
    loading: { 
      type: Boolean,
      default: false
    }
  },
  components: {
    draggable,
    LawSections,
    ckeditor: CKEditor.component,
  },
  computed: {
    ...mapGetters(["darkTheme"]),
    themeClass() {
      return this.darkTheme ? 'dark-theme' : 'light-theme';
    },
  },
  methods: {
    getColorByLevel(level) {
      if (level < 1 || !level) {
        if(this.darkTheme){
          return '#6A9C89';
        } else {
          return '#C1D8C3';
        }
      } 
      const colors = [
        '#D1E9F6', // Level 1
        '#F6EACB', // Level 2
        '#F1D3CE', // Level 3
        '#E0E5B6', // Level 4
        '#F6B8B8', // Level 5
        '#BBE9FF', // Level 6
        '#C6F1D6', // Level 7
        '#E8C5E5', // Level 8
        '#FFFBDA', // Level 9
        '#F6D6D6', // Level 10
      ];
      const index = (level - 1) % colors.length;
      return colors[index];
    },
    // Method to get the last list item number
    getLastListItemNumber(tier) {
      if (tier.listItems && tier.listItems.length > 0) {
        const lastGroup = tier.listItems[tier.listItems.length - 1];
        const lastItem = lastGroup.items && lastGroup.items[lastGroup.items.length - 1];
        if (lastItem && lastItem.no) {
          return parseInt(lastItem.no, 10);
        }
      }
      return 0; // Default to 0 if no list items are found
    },
    // Method to add a sub-tier and predict its number
    addSubTier(path) {
      const tier = this.getTierByPath(path);
      const lastNumber = this.getLastListItemNumber(tier); // Get last item number at current level
      const newSubTier = {
        number: lastNumber + 1, // Increment the last number
        name: '',
        subTiers: [],
        level: tier.level + 1,
      };
      tier.subTiers.push(newSubTier);
    },
    addListItemHandler(path, groupIndex, newItem) {
      this.$emit('addListItem', path, groupIndex, newItem);
    },
    removeListItem(path, groupIndex, index) {
      this.$emit('removeListItem', path, groupIndex, index);
    },
    updateListItemHandler(path, groupIndex, itemIndex, updatedItem) {
      this.$emit('updateListItem', path, groupIndex, itemIndex, updatedItem);
    },
    updateListTitle(path, groupIndex, newTitle){
      this.$emit('listTitle', path, groupIndex, newTitle);
    },
    removeListGroup(path, groupIndex){
      this.$emit('removeListGroup', path, groupIndex);
    },
    addListGroup(path, newGroup){
      this.$emit('addListGroup', path, newGroup);
    },
    getNewPath(path) {
      return path.slice(0, -1);
    },
    getTierByPath(path) {
      return path.reduce((currentTier, index) => {
        return currentTier.subTiers ? currentTier.subTiers[index] : this.tiers[index];
      }, this);
    },
  },
  data() {
    return {
      // CKEditor
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "bulletedList",
            "numberedList",
            "|",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        },
      },
    };
  },
};
</script>
