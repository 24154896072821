<template>
  <v-container>
    <div light>
      <v-row>
        <!-- Main Content Area -->
        <v-col 
          :cols="12" 
          :md="tocDrawer ? 9 : 12"
          style="min-height: 600px;"
          :class="darkTheme ? 'bg-dark-mode' : 'bg-light-mode'"
        >
          <v-row
            v-if="loading"
            justify="center"
            align="center"
            class="fill-height"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              size="64"
            ></v-progress-circular>
          </v-row>
          <div v-else>
            <draggable v-model="tiers" :group="{ name: 'level-1', pull: true, put: ['level-1'] }" animation="200" handle=".handle">
              <tier-item
                v-for="(tier, tierIndex) in tiers"
                class="mb-2"
                :key="tierIndex"
                :tier="tier"
                :path="[tierIndex]"
                :loading="loading" 
                :generateId="generateId"
                @addSubTier="addSubTier"
                @removeTier="removeTier"
                @addContent="addContent"
                @removeContent="removeContent"
                @addListItem="addListItem"
                @removeListItem="removeListItem"
                @updateListItem="updateListItem"
                @listTitle="updateListTitle"
                @removeListGroup="removeListGroup"
                @addListGroup="addListGroup"
              />
            </draggable>
            <v-card-actions class="justify-end pt-0">
              <v-btn v-if="showNewHeadingBtn" @click="addTier" color="primary" small rounded>
                New Heading
              </v-btn>
              <v-btn v-if="showAddListBtn" @click="addListGroupNoParent" color="primary" small rounded>
                List
              </v-btn>
            </v-card-actions>
          </div>
        </v-col>

        <!-- Right Navigation Drawer for Preview -->
        <v-navigation-drawer
          v-if="showTocDrawer"
          v-model="tocDrawer"
          :permanent="!isMobile"
          clipped
          fixed
          right
          :width="tocDrawer ? 270 : 35"
          style="height: 100vh; top: 10rem; max-height: calc(100% - 250px);"
        >
          <!-- Floating Action Button to Collapse/Expand Drawer -->
          <v-btn
            fab
            color="secondary"
            absolute
            top
            small
            left
            :style="{top: '1%', transform:'translate(-90%, -20%)'}"
            @click="tocDrawer = !tocDrawer"
          >
            <v-icon>{{ tocDrawer ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
          </v-btn>

          <!-- Contents only shown when Drawer is expanded -->
          <div v-if="tocDrawer" class="toc-content text-body-2">
            <h6 class="text-overline text-center mt-3">Contents</h6>
            <hr class="mb-2" />

            <!-- Main List -->
            <v-list dense>
              <v-list-group v-for="(tier, tierIndex) in tiers" 
                :key="'toc-tier-' + tierIndex">
                <!-- TOC Tier Header -->
                <template v-slot:activator>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                          @click="scrollToElement([tierIndex])"
                          class="toc-item"
                          v-html="formatTierText(tier, tierIndex)"
                      >
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>

                <!-- TOC List Items -->
                <v-list v-if="tier?.listItems && tier?.listItems?.length > 0" class="toc-sublist">
                  <v-list-group 
                    v-for="(listItem, listIndex) in tier.listItems" 
                    :key="'list-item-' + tierIndex + '-' + listIndex"
                    no-action
                    sub-group
                  >
                    <template v-slot:activator>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title 
                            class="text-caption"
                          >
                            {{ listItem.listTitle ? listItem.listTitle  : "Lists" }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                    <v-list>
                      <v-list-item v-for="(item, itemIndex) in listItem.items" :key="'list-item-' + tierIndex + '-' + listIndex + '-' + itemIndex">
                        <v-list-item-content>
                          <v-list-item-title
                            @click="scrollToList([tierIndex, listIndex])" 
                            class="toc-subitem" 
                            v-html="formatListItemText(item)"
                          >
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-list-group>
                </v-list>

                <!-- TOC Sub Tiers -->
                <v-list v-if="tier.subTiers.length" class="toc-sublist" dense>
                  <toc-item
                    v-for="(subTier, subTierIndex) in tier.subTiers"
                    :key="'toc-subTier-' + tierIndex + '-' + subTierIndex"
                    :tier="subTier"
                    :path="[tierIndex, subTierIndex]"
                    @scrollToElement="scrollToElement"
                    @scrollToList="scrollToList"
                  />
                </v-list>
              </v-list-group>
            </v-list>
          </div>
        </v-navigation-drawer>

      </v-row>
    </div>
  </v-container>
</template>

  
<script>
  import draggable from "vuedraggable";
  import TierItem from "./TierComponent.vue";
  import TocItem from "./TierToc.vue";
  import { mapGetters } from "vuex";
  import { sectionsCollection } from "../../../firebaseDb";
  import { incrementRomanNumeralOrNumber } from "../../utils/romanCounter";

  export default {
    components: {
      draggable,
      TierItem,
      TocItem,
    },
    props: {
      law: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        tocDrawer: false,
        isMobile: false,
        tiers: [],
        loading: true,
        showNewHeadingBtn: false,
        showAddListBtn: false,
      };
    },
    computed: {
      ...mapGetters(['darkTheme']),
      showTocDrawer() {
        return this.$route.name === 'law-editor' && (!this.isMobile || this.tocDrawer);
      }
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkIfMobile);
    },
    watch: {
      tiers: {
        handler(newTiers) {
          // Update law.contents whenever tiers changes
          this.$emit('update-law-contents', JSON.parse(JSON.stringify(newTiers)));
        },
        deep: true // Watch for deep changes
      },
    },
    created() {
      if (this.law.contents && Array.isArray(this.law.contents)) {
        this.tiers = JSON.parse(JSON.stringify(this.law.contents));
      }
    },
    async mounted() {
      this.checkIfMobile();
      window.addEventListener('resize', this.checkIfMobile);
      try {
        // Check if law.contents exists and is an array
        if (this.law && Array.isArray(this.law.contents)) {
          if (this.law.contents.length > 0) {
            this.tiers = JSON.parse(JSON.stringify(this.law.contents));
            const levelOnContent = this.law.contents[0]?.level;
            if (levelOnContent !== undefined && levelOnContent !== null) {
              this.showNewHeadingBtn = true;
              this.showAddListBtn = false;
            } else {
              this.showNewHeadingBtn = false;
              this.showAddListBtn = false;
            }
            await this.fetchSectionsInContents();
          } else {
            console.warn('Content array is empty.');
            this.showAddListBtn = true;
            this.showNewHeadingBtn = true;
          }
        } else {
          console.warn('Content is either undefined or not an array.');
          this.showAddListBtn = true;
          this.showNewHeadingBtn = true;
        }
      } catch (error) {
        console.error('Error in mounted hook:', error);
      } finally {
        this.loading = false;
      }
    },
    methods: {
      checkIfMobile() {
        this.isMobile = window.innerWidth <= 960;
      },
      formatListItemText(item) {
        return `<span class="text-caption"> * ${item.no}<span>`;
      },
      formatTierText(tier, tierIndex) {
        const number = tier?.number || ' ';
        const name = `${tier?.name || `<em>Content ${tierIndex + 1}</em>`}`;
        // Safely return the HTML string with tags
        return `<strong>${number}</strong> ${name}`;
      },
      async fetchSectionsInContents(tiers = this.tiers) {
        try {
          this.loading = true;
          // Fetch all tiers and subTiers concurrently
          await Promise.all(tiers.map(async (tier) => {
            // Resolve list items concurrently
            if (tier.listItems && tier.listItems.length > 0) {
              tier.listItems = await this.resolveListItems(tier.listItems);
            }
            // Recursively resolve subTiers concurrently
            if (tier.subTiers && tier.subTiers.length > 0) {
              await this.fetchSectionsInContents(tier.subTiers);
            }
          }));
          this.loading = false;
        } catch (error) {
          console.error('Error fetching sections:', error);
        }
      },

      // Fetch and resolve listItems by ID
      async resolveListItems(listItems) {
        try {
          const resolvedListItems = await Promise.all(listItems.map(async (listGroup) => {
            if (listGroup.id) {
              const docRef = sectionsCollection.doc(listGroup.id);
              const doc = await docRef.get();
              if (doc.exists) {
                const fetchedData = doc.data();
                // Replace the `items` field (which is a reference) with the fetched `listItems`
                listGroup.items = fetchedData.listItems || [];
                listGroup.listTitle = fetchedData.listTitle || '';
              } else {
                console.error(`Section with ID ${listGroup.id} does not exist.`);
              }
            }
            return listGroup;
          }));

          return resolvedListItems;
        } catch (error) {
          console.error('Error resolving list items:', error);
        }
      },

      addTier() {
        this.showAddListBtn = false;
        const lastTier = this.tiers[this.tiers.length - 1];
        let nextNumber = "";
        if (lastTier && lastTier.number) {
          nextNumber = incrementRomanNumeralOrNumber(lastTier.number);
        }
        this.tiers.push({
          number: nextNumber,
          name: "",
          subTiers: [],
          level: 1
        });
      },
  
      removeTier(path) {
        const parentTier = path.length > 1
          ? this.getTierByPath(path.slice(0, -1)).subTiers
          : this.tiers;
  
        parentTier.splice(path[path.length - 1], 1);
        this.checkContents();
      },
      checkContents() {
        if (!this.tiers || this.tiers.length === 0) {
          console.log("empty contents");
          this.showAddListBtn = true;
          this.showNewHeadingBtn = true;
        }
      },
      addSubTier(path) {
        const tier = this.getTierByPath(path);
        // Predict and set the next number
        const lastSubTier = tier.subTiers[tier.subTiers.length - 1];
        let nextNumber = ""; // Default to "I" if no subTiers exist
        if (lastSubTier && lastSubTier.number) {
          // Use the utility function to get the next Roman numeral or number
          nextNumber = incrementRomanNumeralOrNumber(lastSubTier.number);
        }
        // Add the new sub-tier with the predicted number
        tier.subTiers.push({ 
          number: nextNumber, 
          name: "", 
          subTiers: [], 
          level: tier.level + 1,
        });
      },
  
      addContent(path) {
        const tier = this.getTierByPath(path);
        if (!Object.prototype.hasOwnProperty.call(tier, 'content')) {
          this.$set(tier, 'content', '');
        }
      },
      
      removeContent(path) {
        const tier = this.getTierByPath(path);
        if (Object.prototype.hasOwnProperty.call(tier, 'content')) {
          this.$delete(tier, 'content');
        }
      },

      addListGroupNoParent() {
        this.showNewHeadingBtn = false;
        this.showAddListBtn = false;
        const lastTier = this.tiers[this.tiers.length - 1];
        let nextNumber = "";
        if (lastTier && lastTier.number) {
          nextNumber = incrementRomanNumeralOrNumber(lastTier.number);
        }
        const newTier = {
          number: nextNumber,
          name: "",
          listItems: [],
          subTiers: []
        };
        newTier.listItems.push({
          listTitle: '',
          items: [],
        });
        this.tiers.push(newTier);
      },

      //   LISTS
      // Add Group List
      addListGroup(path, newGroup) {
        const tier = this.getTierByPath(path);
        if (!tier.listItems) {
          this.$set(tier, 'listItems', []);
        }
        tier.listItems.push({
          listTitle: newGroup.listTitle || '',
          items: [],
        });
      },
      // Add a new item to a specific content group (identified by groupIndex)
      addListItem(path, groupIndex, newItem) {
        const tier = this.getTierByPath(path);
        const listGroup = tier.listItems[groupIndex];
        if (!listGroup || !listGroup.items) {
          console.error('Invalid content group or items array not found');
          return;
        }
        listGroup.items.push({
          no: newItem.no || '',
          contents: newItem.contents || ''
        });
      },
      // Remove a list group by index
      removeListGroup(path, groupIndex) {
        const tier = this.getTierByPath(path);
        if (typeof tier.level === 'undefined' || tier.level === null) {
          tier.listItems.splice(groupIndex, 1);
          // After removing, check if the tier is now empty (no listItems, no subTiers, and no content)
          if (tier.listItems.length === 0 && tier.subTiers.length === 0 && !tier.content) {
            this.removeTier(path);
          }
          return;
        }
        if (tier.listItems && tier.listItems.length > 0) {
          tier.listItems.splice(groupIndex, 1);
          return;
        } else {
          console.warn('No list items found to remove');
        }
      },
      // Remove a specific item from a group by groupIndex and itemIndex
      removeListItem(path, groupIndex, itemIndex) {
        const tier = this.getTierByPath(path);
        const listGroup = tier.listItems[groupIndex];

        if (listGroup && listGroup.items.length > 0) {
          listGroup.items.splice(itemIndex, 1);
        }
      },
      // Update a specific item within a group
      updateListItem(path, groupIndex, itemIndex, updatedItem) {
        const tier = this.getTierByPath(path);
        const listGroup = tier.listItems[groupIndex];

        if (listGroup && listGroup.items) {
          this.$set(listGroup.items, itemIndex, updatedItem);
        }
      },
      removeLawSection(path) {
        const tier = this.getTierByPath(path);
        this.$delete(tier, 'listItems');
      },
      // Update the content title of a specific content group
      updateListTitle(path, groupIndex, newTitle) {
        const tier = this.getTierByPath(path);
        const listGroup = tier.listItems[groupIndex];
        if (listGroup) {
          this.$set(listGroup, 'listTitle', newTitle);
        }
      },
      //   end of list
  
      getTierByPath(path) {
        return path.reduce((currentTier, index) => {
          return currentTier.subTiers ? currentTier.subTiers[index] : this.tiers[index];
        }, this);
      },
  
      generateId(path) {
        if (Array.isArray(path)) {
            return 'tier-' + path.join('-');
        }
        return '';
      },

      scrollToList(path){
        // Construct the table ID dynamically
        const newPath = path.slice(0, -1);
        const tableId = 'data-table-' + newPath.join('-');
        const tableElement = document.getElementById(tableId);

        this.scrollToView(tableElement);
      },
      scrollToView(element) {
        if (element) {
          const headerOffset = 120;
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
          const originalBorder = window.getComputedStyle(element).border;
          element.style.border = '5px solid #3A9679';
          setTimeout(() => {
            element.style.border = originalBorder;
          }, 1000);
        }
      },
      scrollToElement(path) {
        this.activePath = path;
        const targetId = this.generateId(path);
        const targetElement = document.getElementById(targetId);
        
        this.scrollToView(targetElement);
      },
    },
  };
</script>

<style scoped>
/* Simplified styles for list and sub-items */
.toc-sublist {
  list-style-type: none;
  padding-left: 10px;
  margin: 2px 0;
}

.toc-subitem {
  text-decoration: none;
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  max-width: 150px;
}

.toc-item-title, 
.toc-item, 
.text-caption {
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
}

.toc-item-title {
  max-width: 100%;
}

.toc-item,
.text-caption {
  max-width: 150px;
}

.toc-subitem:hover {
  color: #4B70F5;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.v-list-item,
.v-list-item-content {
  display: block;
  max-width: 100%;
}

.v-list-item-title a {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-navigation-drawer--mini-variant, 
.v-navigation-drawer {
  overflow: visible !important;
}

.bg-light-mode {
  background-color: #F6F5F5;
  border: 1px solid #546e7a;
  border-radius: 0.5rem;
}

.bg-dark-mode {
  background-color: #31304D;
  border: 1px solid #F6F5F5;
  border-radius: 0.5rem;
}
</style>
