<template>
  <v-container>
    <!-- List Table Section -->
    <v-card class="mb-2" roundes flat>
      <v-row align="center" justify="space-between">
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" @click.stop="openEditTitleDialog" color="primary" class="ma-3">
                mdi-square-edit-outline
              </v-icon>
            </template>
            <span>Edit title</span>
          </v-tooltip>
          <span class="text-body-1 text-center" v-html="getGroupTitle()"></span>
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom dense>
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small fab v-bind="attrs" v-on="on" class="mr-2" color="primary" @click="showEditor = true">
                <v-icon small>mdi-plus-thick</v-icon>
              </v-btn>
            </template>
            <span>Add new item</span>
          </v-tooltip>
          <v-tooltip bottom dense>
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small fab v-bind="attrs" v-on="on" class="mr-3" color="primary" @click="deleteGroup">
              <v-icon small>mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <span>Delete all on this list</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <!-- Show loader while listItems are being fetched -->
      <v-row v-if="loading" justify="center" align="center">
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <p>Loading items, please wait...</p>
        </v-col>
      </v-row>
      
      <v-data-table
        v-else
        ref="dataTable"
        :id="'data-table-' + path.join('-') + '-' + groupIndex"
        :headers="headers"
        :items="Array.isArray(listItems) ? listItems : []"
        :items-per-page="5"
        item-value="no"
        class="elevation-1"
        dense
      >
        <template v-slot:item.no="{ item }">
          <span><strong>{{ item.no }}</strong></span>
        </template>
        <template v-slot:item.contents="{ item }">
          <span v-html="truncateContent(item.contents, 60)"></span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn x-small icon @click="removeListItem(item)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
          <v-btn x-small icon @click="editItem(item)">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- Single Editor Section -->
    <v-expand-transition>
      <v-card light v-show="showEditor" class="pa-3">
        <!-- Row for the input and button -->
        <v-row no-gutters align="center" justify="space-between">
          <v-col cols="6">
            <v-text-field
              v-model.trim="newItem.no"
              label="No."
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col cols="auto">
            <v-btn small color="primary" text class="mr-2" @click="cancelEdit" :tabindex="-1">
              <span>Cancel</span>
            </v-btn>
            <v-btn small color="primary" class="mr-2" @click="addItem" :tabindex="-1">
              <span>{{ editing ? 'Update' : 'Save' }}</span>
            </v-btn>
          </v-col>
        </v-row>

        <!-- Row for the CKEditor -->
        <v-row no-gutters class="mt-3">
          <v-col cols="12">
            <ckeditor
              :editor="editor"
              v-model="newItem.contents"
              :config="editorConfig"
            ></ckeditor>
          </v-col>
        </v-row>
      </v-card>
    </v-expand-transition>

    <!-- Dialog for Editing Section Title -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card class="rounded-xl">
        <v-card-title>Edit Title</v-card-title>
        <v-card-text>
          <v-text-field v-model="titleList" placeholder="Enter title" required solo outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeEditDialog">Cancel</v-btn>
          <v-btn color="primary" rounded @click="saveEditedTitle">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Confirmation Modal -->
    <v-dialog v-model="deleteDialog" persistent max-width="500px">
      <v-card outlined>
        <v-card-title class="headline">Confirm Deletion</v-card-title>
        <v-card-text>
          Are you sure you want to delete <strong class="primary--text">"{{ titleList ? titleList : 'this list' }}"</strong> ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small outlined @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="primary" small @click="removeGroup">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :color="messageType"
      :timeout="2000"
      text
      absolute
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          small
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { incrementRomanNumeralOrNumber, extractNumberOrRoman } from "../../utils/romanCounter"

export default {
  name: "LawSections",
  props: {
    listItems: Array,
    groupTitle: String,
    path: Array,
    groupIndex: Number,
    loading: { 
      type: Boolean,
      default: false
    }
  },
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      // CKEditor setup
      editor: ClassicEditor,
      newItem: {
        no: "",
        contents: "",
      },
      editing: false, // Flag to track whether editing or adding
      editingIndex: null,
      headers: [
        { text: "No.", value: "no", width: "30%" },
        { text: "Content", value: "contents", width: "60%" },
        { text: "Actions", value: "actions", width: "10%", sortable: false },
      ],
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "bulletedList",
            "numberedList",
            "|",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        },
      },
      titleList: '',
      editDialog: false,
      deleteDialog: false,
      showEditor: false,
      snackbar: false,
      message: '',
      messageType: 'error',
    };
  },
  computed: {
    currentPageStartIndex() {
      return (this.$refs.dataTable.page - 1) * this.$refs.dataTable.itemsPerPage;
    }
  },
  watch: {
    // Watch for changes in listItems to predict the next number
    listItems: {
      deep: true,
      handler(newList) {
        if (newList && newList.length > 0) {
          const lastItemNo = newList[newList.length - 1].no;
          this.newItem.no = incrementRomanNumeralOrNumber(lastItemNo);
        }
      }
    }
  },
  methods: {
    getGroupTitle() {
      return this.groupTitle ? `<strong>${this.groupTitle}</strong>` : '<em>Edit Title</em>';
    },
    validateNewItem() {
      if (!this.newItem.no) {
        this.snackbar = true;
        this.message = 'No number provided';
        return false;
      }
      if (!this.newItem.contents) {
        this.snackbar = true;
        this.message = 'Content is empty';
        return false;
      }
      return true;
    },
    addItem() {
      if (!this.validateNewItem()) return;
      const itemPayload = { ...this.newItem };
      if (this.editing) {
        this.$emit('updateListItem', this.path, this.groupIndex, this.editingIndex, itemPayload);
        this.editing = false;
        this.editingIndex = null;
        this.showEditor = false;
        this.snackbar = true;
        this.messageType = "success";
        this.message = 'Item updated successfully';
      } else {
        this.$emit('addListItem', this.path, this.groupIndex, itemPayload);
        this.sortByNo();
        this.snackbar = true;
        this.messageType = "success";
        this.message = 'Item added successfully';
      }
      this.newItem = { no: incrementRomanNumeralOrNumber(this.newItem.no), contents: "" };
    },
    sortByNo() {
      this.listItems.sort((a, b) => {
        const numA = extractNumberOrRoman(a.no);
        const numB = extractNumberOrRoman(b.no);
        return numA - numB;
      });
    },
    cancelEdit() {
      this.showEditor = false;
      this.newItem = { no: "", contents: "" };
      this.editing = false;
    },
    removeListItem(item) {
      this.cancelEdit();
      const index = this.listItems.indexOf(item);
      if (index !== -1) {
        this.$emit('removeListItem', this.path, this.groupIndex, index);
      }
      this.snackbar = true;
      this.message = 'Item removed successfully';
      this.messageType = 'success';
    },
    deleteGroup(){
      this.deleteDialog = true;
    },
    removeGroup() {
      this.$emit('removeListGroup', this.path, this.groupIndex);
      this.deleteDialog = false;
      this.snackbar = true;
      this.message = 'List deleted successfully';
      this.messageType = 'success';
    },
    editItem(item) {
      const itemIndex = this.listItems.findIndex(listItem => listItem.no === item.no);
      if (itemIndex !== -1) {
        const selectedItem = this.listItems[itemIndex];
        this.newItem = { ...selectedItem }; 
        this.editing = true; 
        this.editingIndex = itemIndex; 
        this.showEditor = true;
      }
    },
    openEditTitleDialog() {
      console.log(this.path.join('-'));
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editDialog = false;
    },
    saveEditedTitle() {
      this.$emit('listTitle', this.path, this.groupIndex, this.titleList.trim());
      this.closeEditDialog();
    },
    truncateContent(content, maxLength) {
      const strippedContent = content.replace(/(<([^>]+)>)/gi, "");
      return strippedContent.length > maxLength
        ? strippedContent.substring(0, maxLength) + "..."
        : strippedContent;
    }
  }
};
</script>
