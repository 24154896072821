<template>
    <div>
      <!-- Render the current tier's number, name, and content -->
      <h2 v-if="tier?.number" class="text-center text-h5 font-weight-bold mt-2">{{ tier.number }}</h2>
      <h2 v-if="tier?.name" class="text-center text-h5 font-weight-medium">{{ tier.name }}</h2>
      <p v-if="tier?.content" v-html="tier.content" class="text-body-1 text-justify"></p>
  
      <!-- Render the listItems in the current tier -->
      <div v-if="tier?.listItems?.length" class="mt-3">
        <div v-for="(listGroup, lgIdx) in tier.listItems" :key="'listGroup' + lgIdx">
          <h3 v-if="listGroup?.listTitle" class="text-center text-h5 font-weight-medium">{{ listGroup.listTitle }}</h3>
          <div v-for="(item, itemIdx) in listGroup.items" :key="'item' + itemIdx">
            <p class="font-weight-bold">{{ item.no }}</p>
            <p v-html="item.contents"></p>
          </div>
        </div>
      </div>
  
      <!-- Recursively render subTiers if they exist -->
      <div v-if="tier?.subTiers?.length" class="mt-4">
        <recursive-tier
          v-for="(subTier, subTierIdx) in tier.subTiers"
          :key="'subTier' + subTierIdx"
          :tier="subTier"
        />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'RecursiveTier',
    props: {
      tier: {
        type: Object,
        required: true
      }
    }
  };
  </script>
  