// Function to convert Roman numerals to numbers
export function romanToNumber(roman) {
  const romanMap = { I: 1, V: 5, X: 10, L: 50, C: 100, D: 500, M: 1000 };
  let num = 0;

  for (let i = 0; i < roman.length; i++) {
    const current = romanMap[roman[i]];
    const next = romanMap[roman[i + 1]];

    if (next > current) {
      num -= current;
    } else {
      num += current;
    }
  }
  return num;
}

// Function to convert numbers to Roman numerals
export function numberToRoman(num) {
  const romanMap = [
    { value: 1000, numeral: "M" },
    { value: 900, numeral: "CM" },
    { value: 500, numeral: "D" },
    { value: 400, numeral: "CD" },
    { value: 100, numeral: "C" },
    { value: 90, numeral: "XC" },
    { value: 50, numeral: "L" },
    { value: 40, numeral: "XL" },
    { value: 10, numeral: "X" },
    { value: 9, numeral: "IX" },
    { value: 5, numeral: "V" },
    { value: 4, numeral: "IV" },
    { value: 1, numeral: "I" }
  ];

  let roman = "";
  for (const { value, numeral } of romanMap) {
    while (num >= value) {
      roman += numeral;
      num -= value;
    }
  }
  return roman;
}

// Function to get the next Roman numeral or increment numbers
export function incrementRomanNumeralOrNumber(value) {
  const romanRegex = /[IVXLCDM]+$/i; // Matches Roman numerals at the end
  const numericRegex = /\d+$/;    // Matches numeric values at the end

  let nextValue = value;

  // Check if the value ends with a Roman numeral
  if (romanRegex.test(value)) {
    const lastRoman = value.match(romanRegex)[0].toUpperCase();
    const lastNumber = romanToNumber(lastRoman);
    const nextRoman = numberToRoman(lastNumber + 1);
    nextValue = value.replace(lastRoman, nextRoman);
  } else if (numericRegex.test(value)) {
    // If it's a number, increment it
    const lastNumber = parseInt(value.match(numericRegex)[0], 10) + 1;
    nextValue = value.replace(numericRegex, lastNumber);
  } else {
      // Return null if it's not valid
      return null;
  }

  return nextValue;
}

// Function to convert Roman numerals to integers
function romanToInt(roman) {
  const romanMap = { I: 1, V: 5, X: 10, L: 50, C: 100, D: 500, M: 1000 };
  let total = 0, prevValue = 0;

  for (let i = roman.length - 1; i >= 0; i--) {
    const currentValue = romanMap[roman[i]];
    total += currentValue < prevValue ? -currentValue : currentValue;
    prevValue = currentValue;
  }

  return total;
}

// Function to extract the numeric or Roman numeral part from the string
export function extractNumberOrRoman(str) {
  const numberMatch = str.match(/\d+/); // Match numbers
  const romanMatch = str.match(/\b[IVXLCDM]+\b/); // Match Roman numerals

  if (numberMatch) {
    return parseInt(numberMatch[0]); // Return the numeric part if found
  } else if (romanMatch) {
    return romanToInt(romanMatch[0]); // Return the Roman numeral part converted to a number
  } else {
    return 0; // Return 0 if no number or Roman numeral is found
  }
}

